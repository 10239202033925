/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    h2: "h2",
    div: "div",
    br: "br",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, TwoColCta, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’ve been researching the best ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "device to improve your hearing"), ", you may be finding it a bit challenging to make sense of the ", React.createElement(_components.strong, null, "different types of hearing aids"), " on the market today. You’re most likely wondering how each device differs from the next. How do you know if it’s the right hearing aid for you? Will it help you better understand speech so you can hear normally again? And, is it easy to use?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before you buy, there are some key things you should understand to ensure you choose the right hearing aid. You want to make the best selection that suits your lifestyle and solves for your individual hearing loss, because all hearing aid types are not appropriate for everyone."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "behind-the-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#behind-the-ear",
    "aria-label": "behind the ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Behind the ear"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/behind-the-ears-hearing-aids-on-man.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "A ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "behind-the-ear (BTE) hearing aid"), " sits behind your ear and connects over the top of your ear via a very thin tube to an earpiece inside your ear canal. A BTE usually can solve for a wide range of hearing loss from mild to severe. The loudspeaker, or receiver, may be housed in the case (traditional BTE) or in the ear mold or dome tip (receiver-in-the-canal, or RIC). The RIC style of BTE hearing aid is often smaller than a traditional BTE and more commonly used in more active populations.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These types of hearing aids used to be some of the largest hearing devices of the past. Most likely it’s what you saw your grandparents wearing. But BTE hearing aids have done a complete 180 and now are some of the smallest, sleekest devices on the market. And they’re packed with incredible technology that can help conquer any hearing barrier in any environment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many now come equipped with rechargeable batteries, ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth technology"), " so you can stream music, TV, podcasts and more right into your ears, smartphone connectivity for discreet controls, acoustic motion sensors that sense your changing environment and adapt automatically, speech enhancement features, and much more."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "completely-in-canal",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#completely-in-canal",
    "aria-label": "completely in canal permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Completely in canal"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/completely-in-canal-hearing-aids.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Completely-in-canal (CIC) hearing aids are the smallest of all devices and disappear completely inside the ear canal. Known as a nearly invisible hearing aid, no one will know you’re wearing them. They are removed by gently pulling on the small attached wire that sits on the end of each device. Like the IIC devices, CIC hearing aids are best for those with mild to moderate hearing loss. Many of the more recent devices come equipped with enhanced technology chips for ultra-clear speech clarity and smartphone connection.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "in-the-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-ear",
    "aria-label": "in the ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In the ear"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/in-the-ear-hearing-aids-type.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "In-the-ear (ITE) hearing aids"), " sit just inside the ear (not the ear canal), closer to the outer ear. These types of hearing aids are usually molded to your ear to form a custom fit, as no two ears are alike.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "ITE hearing devices are best for mild to moderate hearing loss. Most are not rechargeable and require you to change batteries every few days, depending on your wear time. Many ITE hearing aids today do come with smartphone connectivity, so you’ll be able to control the volume and create sound profiles from the palm of your hand.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "invisible-in-canal",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#invisible-in-canal",
    "aria-label": "invisible in canal permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Invisible-in-canal"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Invisible-in-canal (IIC) hearing aids are inserted inside the ear canals. While a portion of the device can still be seen, they’re still considered extremely small and not super noticeable. These types of hearing aids are best suited for those with mild to moderate hearing loss. As with the ITE, many IIC hearing devices come with smartphone connectivity for convenient, easy adjustments anywhere, anytime."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cros",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cros",
    "aria-label": "cros permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CROS"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Known as the ", React.createElement(_components.a, {
    href: "/hearing-aids/types/cros-bicros/",
    className: "c-md-a"
  }, "Contralateral Routing of Signals (CROS) hearing aids"), ", these hearing aids are made specifically for those who are deaf in one ear. But when a CROS system is used, a hearing aid is actually worn on both ears. Here’s why. Normally if someone has hearing loss in one ear, and there is noise or speech on that side, they can’t hear it. But when a CROS hearing aid system is used, the device on or in the bad ear sends sound signals that come in on that side to the side with the better ear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-choose-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-choose-your-hearing-aids",
    "aria-label": "how to choose your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to choose your hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Buying hearing aids shouldn’t be a quick purchase. Following the proper steps is crucial to adapting to and succeeding with hearing aids."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "First, you should get a professional hearing test with a reputable audiologist or hearing specialist. This will determine your level of hearing loss so you can narrow down the different types of hearing aids that will work the best for you."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Second, you want to consider your lifestyle."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "How active are you?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are you social and often in group conversations, or do you tend to talk with people more one-on-one?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do you find yourself in noisier or quieter environments?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do you want to hear the television more clearly, and would you like to stream sound directly into your hearing aids? (This means you can turn the actual TV volume down!)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Writing down your activities and how you spend your time will help you and your doctor choose a hearing aid with features that match your lifestyle."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-your-best-hearing-aid-fit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-your-best-hearing-aid-fit",
    "aria-label": "find your best hearing aid fit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find your best hearing aid fit"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the exclusive ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX line of hearing aids by hear.com"), ". No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-assistance-from-an-experienced-audiologist-near-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-assistance-from-an-experienced-audiologist-near-you",
    "aria-label": "get assistance from an experienced audiologist near you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get assistance from an experienced audiologist near you"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you choose to work with hear.com, you can count on supportive, reputable care and guidance. We will talk with you over the phone to understand your needs. Next, you’ll be scheduled to meet with one of our high-quality hearing specialists that are conveniently located near your neighborhood. Or, you may qualify for an extra-convenient virtual appointment from the comfort of your home. And, we do all the scheduling for you so you can just sit back and relax!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Answer a few easy questions to see if you qualify to try the best type of hearing aid for you in your everyday life for 45 days. There is no risk and pressure to buy. If they don’t work for you, simply send them back. But we have no doubt you’ll be loving life with your new hearing aids and hearing life at its fullest once again."), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Choose the best hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
